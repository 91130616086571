<style scoped>
.not_use {
    background-color: #f4f4f4;
    color: #777777;
}
</style>

<template>
    <v-card outlined class="mb-10">
        <v-card-title class="pl-7 block-title">업체명 설정</v-card-title>
        <v-card-text class="pa-0">
            <v-simple-table :class="{'not_use': wvUseYn == 'N'}">
                <tr>
                    <td class="pt-5 pl-7 pr-7 pb-5 block-line-head block-br" style="width:200px;">화면에 표시될<br/>회사명</td>
                    <td class="pt-5 pl-7 pr-7 pb-5">
                        <v-radio-group class="mt-0" hide-details v-model="companyName.logo_use_yn">
                            <v-radio value="N" class="mb-4">
                                <template v-slot:label>
                                    <div>
                                        <div style="float:left; width:150px;" class="pt-5 pb-3"><span style="color:#333333; font-size:18px;">텍스트로 표시</span></div>
                                        <div style="float:left; width:560px;">
                                            <v-text-field outlined hide-details v-model="companyName.name_text"></v-text-field>
                                        </div>
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio value="Y">
                                <template v-slot:label>
                                    <div>
                                        <div style="float:left; width:150px;" :class="companyName.logo_url == '' ? 'pt-9 pb-3' : 'pt-12 pb-3'"><span style="color:#333333; font-size:18px;">이미지로 표시</span></div>
                                        <div style="float:left; width:560px;">
                                            <v-btn outlined color="grey" class="mb-3" style="width:560px; height:80px;" @click="openUploadLogoCrop" v-if="companyName.logo_url == ''">
                                                <v-icon color="#4E87DD" class="mr-2">mdi-plus-circle</v-icon>
                                                <span style="color:#555555; font-size:16px; font-weight:600;">이미지 추가하기</span>
                                            </v-btn>
                                            <v-card flat outlined class="mb-3" style="width:500px;" v-if="companyName.logo_url">
                                                <v-img :src="companyName.logo_url" class="grey lighten-2" v-on:click="openImgPopup(companyName.logo_url)"></v-img>
                                            </v-card>
                                            <v-btn color="success" small v-on:click="clearLogo" v-if="companyName.logo_url">이미지 삭제</v-btn>
                                        </div>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </td>
                </tr>
            </v-simple-table>
        </v-card-text>
        <v-dialog v-model="modal" persistent max-width="650">
            <v-flex v-if="dialogMode === 'img'">
                <img-preview-popup :url="popupImgUrl" v-on:cancel="closePopup"></img-preview-popup>
            </v-flex>
            <v-flex v-if="dialogMode === 'upload_logo_crop'">
                <upload-logo-crop-popup v-on:submit="applyLogo" v-on:cancel="closePopup"></upload-logo-crop-popup>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'svc_wv_company_name',
    components: {
        'imgPreviewPopup': () => import('@/components/svc/wv/popup_img_preview'),
        'uploadLogoCropPopup': () => import('@/components/svc/wv/popup_upload_logo_crop'),
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        popupImgUrl: '',
    }),
    created() {
    },
    mounted() {
    },
    computed: {
        ...mapState('svcWv', {
            wvUseYn: state => state.wvUseYn,
            companyName: state => state.companyName,
        }),
    },
    methods: {
        openImgPopup(u) {
            if (u) {
                this.popupImgUrl = u
                this.dialogMode = 'img'
                this.modal = true
            }
        },
        clearLogo() {
            this.$set(this.companyName, 'logo_url', '')
        },
        openUploadLogoCrop() {
            this.dialogMode = 'upload_logo_crop'
            this.modal = true
        },
        applyLogo(d) {
            if (d) {
                this.$set(this.companyName, 'logo_url', d)
                this.closePopup()
            }
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'NONE'
        },

    }
}
</script>